import React from 'react'
import ScrollToTop from '../components/ScrollToTop'
import Contact from '../components/Contact'

const ContactPage = () => {
    return (
        <>
            <ScrollToTop/>
            <Contact/>
        </>
    )
}

export default ContactPage
